@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: url('./assets/stars2.png') repeat;
  animation: moveBg 30s linear infinite;
}

@keyframes moveBg {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -1000px 0;
  }
}

.app {
  text-align: center;
  background-color: rgba(0, 128, 128, 0.8); /* Teal blue background with transparency */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.titleContainer {
  position: relative;
  display: inline-block;
}

.title {
  font-family: 'Orbitron', sans-serif; /* Apply the new font */
  font-size: 4rem;
  z-index: 1;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  position: relative;
  top: -20px; /* Move the text higher */
}

.logo {
  position: relative;
  height: 200px; /* Increase the size of the logo */
  z-index: 0;
  margin-top: 10px; /* Add space above the logo */
}

.socialMediaButtons {
  display: flex;
  justify-content: center;
  gap: 10px; /* Add space between buttons */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  margin-top: 20px;
}

button {
  background-color: #C0C0C0; /* Light grey background */
  border: 2px solid #808080; /* Dark grey border */
  color: black;
  font-size: 12px;
  padding: 10px; /* Adjust padding for better spacing */
  margin: 10px; /* Adjust margin for better spacing */
  cursor: pointer;
  font-family: 'Press Start 2P', cursive; /* Cool retro font */
  box-shadow: inset 2px 2px 0px #FFFFFF, inset -2px -2px 0px #808080; /* Windows 98 style shadow */
  width: 150px; /* Fixed width for equal size */
  height: 50px; /* Fixed height for equal size */
  text-align: center;
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

button:hover {
  background-color: #A0A0A0; /* Slightly darker grey for hover */
}

.socialMediaButtons a {
  text-decoration: none; /* Remove underline from anchor elements */
}

/* Media queries for mobile optimization */
@media (max-width: 600px) {
  .title {
    font-size: 2rem; /* Adjust font size for title */
    white-space: normal; /* Allow text to wrap */
    padding: 0 10px; /* Add padding to ensure text stays within bounds */
  }

  button {
    width: 90%; /* Full width minus some margin for buttons on mobile */
    max-width: none; /* Remove max-width for mobile */
    margin: 5px 0; /* Reduce margin for mobile */
    font-size: 10px; /* Reduce font size for better fit */
  }

  .mp3Player {
    margin-top: 15px; /* Adjust spacing */
  }

  .titleContainer {
    margin-bottom: 10px; /* Adjust margin for better spacing */
  }

  .logo {
    height: 150px; /* Adjust logo size */
  }

  .socialMediaButtons {
    flex-direction: column; /* Stack buttons vertically on mobile */
    align-items: center; /* Center buttons horizontally */
  }
}
